<template>
    <RequireLogin>
        <div v-if="isEnterpriseAccount">
            <slot></slot>
        </div>
        <div v-if="!isEnterpriseAccount && enterpriseAccountList.length > 1">
            <v-row justify="center" class="py-5">
                <p class="text-h4">Select an enterprise account to continue</p>
            </v-row>
            <v-row justify="center" class="pb-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="6">
                        <v-list class="white" style="padding-top: 0px; padding-bottom: 0px;">
                            <div v-for="(account, idx) in enterpriseAccountList" v-bind:key="idx">
                                <v-divider v-if="idx > 0"></v-divider>
                            <v-list-item @click="selectAccount(account.id)">
                                <v-list-item-avatar>
                                    <!-- TODO: replace this with arbitrary icon from account settings, and set the gravatar URL when editing the account , or enabling/disabling the isGravatarEnabled property on the account -->
                                    <v-gravatar v-if="account.isGravatarEnabled" :email=account.email :size="240"></v-gravatar>
                                    <font-awesome-icon v-if="!account.isGravatarEnabled" icon="user-circle" fixed-width size="2x"/>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ account.name }}</v-list-item-title>
                                    <v-list-item-subtitle class="text-overline" style="margin-left: 2px">{{ account.type }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            </div>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div v-if="!isEnterpriseAccount && enterpriseAccountList.length === 0">
            <v-row justify="center" class="pt-10">
                <p class="text-h5">You need an enterprise account to continue</p>
            </v-row>
            <!--
            <v-row justify="center" class="py-5">
                <v-btn elevation="6" class="blue white--text" to="/subscribe/enterprise">
                    Create an enterprise account
                </v-btn>
            </v-row>
            -->
        </div>
    </RequireLogin>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import RequireLogin from '@/components/RequireLogin.vue';
import { ACCOUNT_TYPE_ENTERPRISE } from '@/constants';

export default {
    components: {
        RequireLogin,
    },
    methods: {
        selectAccount(target) {
            console.log('RequireEnterpriseAccount.vue: selectAccount: %o', target);
            this.$store.dispatch('switchAccount', target);
        },
        autoselect() {
            // if the user has only one enterprise account, switch to it automatically
            if (this.isReady && !this.isEnterpriseAccount && this.enterpriseAccountList.length === 1) {
                this.selectAccount(this.enterpriseAccountList[0].id);
            }
        },
    },
    computed: {
        ...mapState({
            session: (state) => state.session,
            isReady: (state) => state.isReady,
        }),
        ...mapGetters({
            currentAccount: 'account',
            enterpriseAccountList: 'enterpriseAccountList',
        }),
        isEnterpriseAccount() {
            return this.currentAccount && this.currentAccount.type === ACCOUNT_TYPE_ENTERPRISE && this.currentAccount.permit && this.currentAccount.permit.includes('edit');
        },
    },
    mounted() {
        console.log('RequireEnterpriseAccount.vue: mounted');
        this.autoselect();
    },
    watch: {
        isReady(val, oldVal) {
            if (val && !oldVal) {
                this.autoselect();
            }
        },
    },
};
</script>
