<template>
    <AppLayout>
    <RequireEnterpriseAccount>
        <v-snackbar v-model="snackbarUserAdded" :timeout="2000" top color="blue" class="mt-0 pt-0">
            <span>Added user and sent invitation</span>
            <v-btn text  class="blue white--text" @click="snackbarUserAdded = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>
         <v-snackbar v-model="snackbarUserAddedFailed" :timeout="2000" top color="red" class="mt-0 pt-0">
            <span>Failed to send invitation</span>
            <v-btn text  class="red white--text" @click="snackbarUserAddedFailed = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>
        <v-snackbar v-model="snackbarUserEdited" :timeout="2000" top color="blue" class="mt-0 pt-0">
            <span>Saved changes</span>
            <v-btn text  class="blue white--text" @click="snackbarUserEdited = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>
         <v-snackbar v-model="snackbarUserEditedFailed" :timeout="2000" top color="red" class="mt-0 pt-0">
            <span>Failed to save changes</span>
            <v-btn text  class="red white--text" @click="snackbarUserEditedFailed = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>
        <v-snackbar v-model="snackbarUserDeleted" :timeout="2000" top color="blue" class="mt-0 pt-0">
            <span>Deleted user</span>
            <v-btn text  class="blue white--text" @click="snackbarUserDeleted = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>
         <v-snackbar v-model="snackbarUserDeletedFailed" :timeout="2000" top color="red" class="mt-0 pt-0">
            <span>Failed to delete user</span>
            <v-btn text  class="red white--text" @click="snackbarUserDeletedFailed = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>
        <v-row justify="center" class="py-5" v-if="currentAccount">
            <h1 class="text-h6 font-weight-light">{{ currentAccount.name }}</h1>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" class="text-center pa-0">
                <v-card elevation="12">
                    <v-card-title>
                        Users
                        <v-divider class="mx-4" vertical></v-divider>
                        <v-text-field
                            v-model="search"
                            label="Search"
                            single-line
                            hide-details
                            outlined>
                            <template #append>
                                <font-awesome-icon icon="search" fixed-width class="mt-1"/>
                            </template>
                        </v-text-field>
                        <v-divider class="mx-4" vertical></v-divider>
                        <v-dialog v-model="dialogEditUser" max-width="800px">
                            <template #activator="{ on }">
                                <v-btn elevation="6" color="blue" dark class="mb-2" v-on="on">New User</v-btn>
                            </template>
                            <v-card elevation="12" class="px-3 pb-3">
                                <v-card-title>
                                    <v-row justify="center">
                                        <span class="text-h5">{{ formTitle }}</span>
                                        <span class="pr-5" style="position: absolute; right: 0;">
                                            <v-btn icon color="grey" href="#" @click="dialogEditUser = false">
                                                <font-awesome-icon icon="times" fixed-width style="font-size: 16px;"/>
                                            </v-btn>
                                        </span>
                                    </v-row>
                                </v-card-title>
                                <v-divider class="mx-5"></v-divider>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedUser.name" label="Full Name" outlined>></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedUser.email" label="Email" outlined>></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedUser.phone" label="Phone" outlined>></v-text-field>
                                        </v-col>
                                        <!--
                                        <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedUser.department" label="Department" outlined>></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedUser.employeeID" label="Employee ID" outlined>></v-text-field>
                                        </v-col>
                                        -->
                                    </v-row>
                                </v-container>
                                <v-row justify="center">
                                    <v-btn class="blue white--text" @click="save">Save</v-btn>
                                </v-row>
                            </v-card>
                        </v-dialog>
                    </v-card-title>
                    <v-data-table
                    :headers="headers"
                    :items="users"
                    :search="search"
                    sort-by="name"
                    :loading="isLoading"
                    loading-text="Loading... Please wait"
                    :footer-props="{
                        showFirstLastPage: true,
                    }">
                        <!-- https://vuejs.org/v2/guide/components-slots.html#Dynamic-Slot-Names -->
                        <template #[`item.action`]="{ item }">
                            <v-tooltip top>
                                <template #activator="{ on }">
                                    <v-btn text class="blue--text" v-on="on"><font-awesome-icon @click="editUser(item.userId)" icon="pencil-alt" fixed-width/></v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template #activator="{ on }">
                                    <v-btn text class="blue--text" v-on="on"><font-awesome-icon @click="deleteUser(item.userId)" icon="trash" fixed-width/></v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </template>
                        <template #no-data>
                            <p>Add your first user</p>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </RequireEnterpriseAccount>
    </AppLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import RequireEnterpriseAccount from '@/components/RequireEnterpriseAccount.vue';
import AppLayout from '@/components/AppLayout.vue';

export default {
    components: {
        RequireEnterpriseAccount,
        AppLayout,
    },
    data() {
        return {
            snackbarUserAdded: false,
            snackbarUserAddedFailed: false,
            snackbarUserEdited: false,
            snackbarUserEditedFailed: false,
            snackbarUserDeleted: false,
            snackbarUserDeletedFailed: false,
            search: '',
            dialogEditUser: false,
            isLoading: false,
            editedIndex: -1,
            nextID: 6,
            editedUser: {
                accountId: '',
                userId: '',
                name: '',
                email: '',
                phone: '',
                // department: '',
                // employeeID: '',
            },
            defaultUser: {
                accountId: '',
                userId: '',
                name: '',
                email: '',
                phone: '',
                // department: '',
                // employeeID: '',
            },
            headers: [
                {
                    text: 'Full Name', align: 'left', sortable: true, value: 'name',
                },
                { text: 'Email', value: 'email' },
                { text: 'Phone', value: 'phone' },
                /*
                { text: 'Department', value: 'department' },
                { text: 'Employee ID', value: 'employeeID' },
                */
                {
                    text: 'Actions', value: 'action', sortable: false, align: 'center',
                },
            ],
        };
    },
    created() {
    },
    mounted() {
        this.init();
    },
    watch: {
        /*
        isReady(val, oldVal) {
            if (val && !oldVal) {
                this.init();
            }
        },
        */
        currentAccount(account) {
            console.log('EnterpriseUsers.vue: watch currentAccount: %o', account);
            if (account && account.id) {
                this.init();
            }
        },
        // Close when clicking outside of dialog
        dialogEditUser(val) {
            if (!val) {
                this.close();
            }
        },
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New User' : 'Edit User';
        },
        ...mapState({
            isReady: (state) => state.isReady,
            enterpriseUserList: (state) => state.enterpriseUserList,
        }),
        ...mapGetters({
            currentAccount: 'account',
        }),
        users() {
            const list = [];
            if (this.enterpriseUserList) {
                this.enterpriseUserList.forEach((item) => {
                    list.push({
                        accountId: this.currentAccount.id,
                        userId: item.userId,
                        name: item.name,
                        email: item.email,
                        phone: item.phone,
                    });
                });
            }
            return list;
        },
    },
    methods: {
        init() {
            if (this.currentAccount) {
                console.log('EnterpriseUsers.vue: init: currentAccount.id = %s', this.currentAccount.id);
                this.loadUserList();
            } else {
                console.log('EnterpriseUsers.vue: init: currentAccount not available');
            }
        },
        async loadUserList() {
            console.log('enterpriseusers.vue: loading user list...');
            this.isLoading = true;
            await this.$store.dispatch('loadEnterpriseUserList');
            this.isLoading = false;
            console.log('enterpriseusers.vue: loaded user list: %o', this.enterpriseUserList);
        },
        editUser(userId) {
            console.log('Edit User called');
            for (let i = 0; i < this.users.length; i += 1) {
                if (this.users[i].userId === userId) {
                    this.editedIndex = i;
                    this.editedUser = { ...this.users[i] };
                    break;
                }
            }
            console.log('editedIndex:', this.editedIndex);
            console.log('User: %o', this.editedUser);
            this.dialogEditUser = true;
        },
        async deleteUser(userId) {
            /*
            let index = null;
            for (let i = 0; i < this.users.length; i += 1) {
                if (this.users[i].userId === userId) {
                    index = i;
                    break;
                }
            }
            this.users.splice(index, 1);
            */
            console.log('EnterpriseUsers.vue: deleteUser accountId: %s  userId: %s', this.currentAccount.id, userId);
            const result = await this.$store.dispatch('unlinkAccountUser', { accountId: this.currentAccount.id, userId });
            if (result) {
                this.snackbarUserDeleted = true;
            } else {
                this.snackbarUserDeletedFailed = true;
            }
        },
        async save() {
            if (this.editedIndex > -1) {
                // Object.assign(this.users[this.editedIndex], this.editedUser);
                const result = await this.$store.dispatch('editAccountUser', this.editedUser);
                if (result) {
                    this.snackbarUserEdited = true;
                } else {
                    this.snackbarUserEditedFailed = true;
                }
            } else {
                // this.editedUser.id = this.nextID;
                // this.nextID += 1;
                const newUser = {
                    name: this.editedUser.name,
                    email: this.editedUser.email,
                    phone: this.editedUser.phone,
                    accountId: this.currentAccount.id,
                };
                await this.$store.dispatch('inviteUser', newUser);
                this.loadUserList(); // reload user list, should include newly invited user
                this.snackbarUserAdded = true;
            }
            this.close();
        },
        close() {
            console.log('Close called');
            this.dialogEditUser = false;
            this.editedUser = { ...this.defaultUser };
            this.editedIndex = -1;
        },
    },
};
</script>
